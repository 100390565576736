import { render, staticRenderFns } from "./view_projects.vue?vue&type=template&id=001f3c65"
import script from "./view_projects.vue?vue&type=script&lang=js"
export * from "./view_projects.vue?vue&type=script&lang=js"
import style0 from "./view_projects.vue?vue&type=style&index=0&id=001f3c65&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports